export class CommonConstant {
    static ON_DESTROY : string = "ON_DESTROY";

    // storage
    static CART_INFO_TMP : string = "cardInfoTmp";
    static LINK_CART : string = "linkCart";
    static LINK_CART_PRODUCTS : string  = "linkCartProducts";
    static LINK_GUEST : string = "linkGuest";
    static PRODUCT_RECENT : string = "pdtClickRecent";
    static LEN_CART : string = "lenCart";
    static PAGE_CART : string = "pageCart";
    static URL_WOWNET : string = "urlWownet";
    static CHECK_ITEM_PRODUCT : string = "checkItemProduct";
    static STR_IS_CHECK_REGISTER : string = "str_is_check_register";
    static USER_ID2 : string = "userid2";
    static CONSUMER : string = "consumer";
    static USER_ID : string = "userId";
    static MEMBER : string = "member";

    // Common
    static YES = "Y";
    static NO = "N";
    static SLASH = "/";
    static PV1 = "pv1";
    static PV2 = "pv2";
    static PV3 = "pv3";
    static KOREA_CODE = "KR";
    static BLANK = "";
    static LAST_MONTH = 12;
    static SELECT_LABEL = "선택";
}
